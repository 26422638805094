import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import dayjs, { Dayjs } from "dayjs"

import DateSelector from "./DateSelector"
import TimeframeSelectionPanel from "./TimeframeSelectionPanel"
import { TimeFrameOption } from "~/types"

interface ControlPanelProps {
  readonly currentDate: Dayjs
  readonly currentTimeFrame: TimeFrameOption
  readonly onChangeDate: (date: Dayjs) => void
  readonly onChangeTimeFrame: (timeFrame: TimeFrameOption) => void
}

function ControlPanel({
  currentDate,
  currentTimeFrame = TimeFrameOption.DAY,
  onChangeDate,
  onChangeTimeFrame,
}: ControlPanelProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0.3125rem",
        paddingLeft: "0.625rem",
        paddingRight: "0.625rem",
      }}
    >
      <Button onClick={() => onChangeDate(dayjs())}>{t("component.dispatchCalendar.today")}</Button>
      <DateSelector
        currentDate={currentDate}
        currentTimeFrame={currentTimeFrame}
        onChange={(date) => onChangeDate(date)}
        onScrollNext={() => {
          let nextDate = dayjs(currentDate).add(1) // default to DAY timeframe
          switch (currentTimeFrame) {
            case TimeFrameOption.DAY:
              nextDate = dayjs(currentDate).add(1, "d")
              break
            case TimeFrameOption.WEEK:
              nextDate = dayjs(currentDate).add(1, "w")
              break
            case "MAP":
              nextDate = dayjs(currentDate).add(1, "d")
              break
          }
          onChangeDate(nextDate)
        }}
        onScrollPrevious={() => {
          let previousDate = dayjs(currentDate).subtract(1) // default to DAY timeframe
          switch (currentTimeFrame) {
            case TimeFrameOption.DAY:
              previousDate = dayjs(currentDate).subtract(1, "d")
              break
            case TimeFrameOption.WEEK:
              previousDate = dayjs(currentDate).subtract(1, "w")
              break
            case "MAP":
              previousDate = dayjs(currentDate).subtract(1, "d")
              break
          }
          onChangeDate(previousDate)
        }}
      />
      <TimeframeSelectionPanel currentSelection={currentTimeFrame} onChange={onChangeTimeFrame} />
    </Box>
  )
}

export default ControlPanel
