import gql from "graphql-tag"

export const GET_INBOX_CONVERSATION_BY_ID = gql`
  query GetInboxConversationById($id: ID!, $markAsRead: Boolean, $organizationId: ID!) {
    getInboxConversationById(id: $id, markAsRead: $markAsRead) {
      id
      isRead
      isArchived
      status
      latestMessage {
        id        
      }
      messages {
        id
        subject
        sender {
          name
          messageAddress
          user {
            id
            firstName
            lastName
            email
          }
          contact {
            id
            firstName
            lastName
            email
          }
        }
        bodyHtml
        bodyPlain
        subject
        toRecipients {
          name
          messageAddress
        }
        ccRecipients {
          name
          messageAddress
        }
        events {
          id
          type
          messageId
          externalEventId
          timestamp
          details
          messageAddress
        }
        attachments {
          id
          name
          contentType
          signedUrl
          previews(sizes: [MEDIUM]) {
            id
            contentType
            signedUrl
          }
        }
        channel
        direction
        dateSent
        createdAt
      }
      tags {
        id
        name
      }
      job {
        id
        number
        customer {
          id
          name
        }
        address {
          addressString
          streetNumber
          route
          locality
          administrativeAreaLevel1
          administrativeAreaLevel2
          postalCode
          country
        }
      }
      customer {
        id
        name
        email
      }
    }
    getOrganizationById(id: $organizationId) {
      id
      unreadInboxConversationCount
    }
  }
  
`