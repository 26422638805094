import React from "react"
import { useTranslation } from "react-i18next"
import ChannelHeader from "./ChannelHeader"
import CategoryHeader from "./CategoryHeader"
import Divider from "@mui/material/Divider"

interface Props {
  readonly title: string
  readonly description: string
}

function CategorySectionHeader({ title, description }: Props) {
  const { t } = useTranslation()
  return (
    <>
      <CategoryHeader description={description} title={title} />
      <ChannelHeader label={t("component.notificationSettings.channel.email")} />
      <ChannelHeader label={t("component.notificationSettings.channel.sms")} />
      <ChannelHeader label={t("component.notificationSettings.channel.notificationCenter")} />
      <Divider sx={{ gridColumn: "1 / -1 ", marginTop: "0.5rem", marginBottom: "1rem" }} />
    </>
  )
}

export default CategorySectionHeader
