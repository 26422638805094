/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { useTranslation } from "react-i18next"
import Downshift from "downshift"
import { useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import { TextFieldProps } from "@mui/material/TextField"
import Paper from "@mui/material/Paper"
import MenuItem from "@mui/material/MenuItem"

import { ALL_JOBS } from "~/queries/allJobs"
import { Job } from "~/types"
import FielderTextField from "./FielderTextField"
import { getAddressSummary } from "~/util"

function renderInput(props: TextFieldProps) {
  return (
    <FielderTextField {...props} InputProps={{ ...props.InputProps, disableUnderline: true }} />
  )
}

interface SuggestionsProps {
  readonly getItemProps: (item: any) => any
  readonly getMenuProps: () => any
  readonly inputValue: string
}

function Suggestions({ getItemProps, getMenuProps, inputValue }: SuggestionsProps) {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(ALL_JOBS, {
    variables: {
      filter: inputValue,
      sortBy: "number",
      sortDir: "ASC",
      first: 5,
    },
    context: {
      debounceKey: "JobTypeahead",
      debounceTimeout: 500,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  })

  if (error) {
    return <div>Error! ${error.message}</div>
  }

  const options: Job[] = data?.allJobs?.edges?.map((e: { node: Job }) => e.node) ?? []

  return (
    <Paper sx={{ position: "absolute", zIndex: 1000, width: "100%" }}>
      <ul
        style={{ listStyle: "none", margin: 0, padding: 0, position: "relative", outline: 0 }}
        {...getMenuProps()}
      >
        {loading ? (
          <Box
            sx={{
              flexGrow: 1,
              lineHeight: "normal",
              padding: "1rem",
              position: "absolute",
              zIndex: 1000,
              width: "100%",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "0.5rem",
              borderRadius: "4px",
              boxShadow:
                "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
            }}
          >
            <CircularProgress size={16} thickness={6.0} /> {t("loadingJobs")}
          </Box>
        ) : (
          options.map((option: Job) => {
            return (
              <MenuItem
                dense
                key={option.id}
                sx={{
                  borderBottom: "1px solid #ededed",
                  overflow: "hidden",
                }}
                {...getItemProps({ item: option })}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    lineHeight: "normal",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <span>{`${t("job")} #${option.number}`}</span>
                  {option.customer ? <span> - {option.customer.name}</span> : null}
                  {option.address ? <span> - {getAddressSummary(option.address)}</span> : null}
                </Box>
              </MenuItem>
            )
          })
        )}
      </ul>
    </Paper>
  )
}

interface JobTypeaheadProps {
  readonly currentJob?: Job | null
  readonly error?: boolean
  readonly label: string
  readonly onBlur?: (event: any) => void
  readonly onSelect: (job: Job | null) => void
  readonly required?: boolean
}

function JobTypeahead({ currentJob, error, label, onBlur, onSelect, required }: JobTypeaheadProps) {
  const { t } = useTranslation()
  return (
    <Downshift
      itemToString={(item: Job | null) =>
        item ? `#${item.number} - ${item.customer?.name} - ${getAddressSummary(item.address)}` : ""
      }
      onChange={(item: Job | null) => onSelect(item)}
      selectedItem={currentJob}
    >
      {({ getInputProps, getMenuProps, getItemProps, getLabelProps, inputValue = "", isOpen }) => {
        const { onFocus, ...inputProps } = getInputProps()

        // We have to use <div> here instead of <Box> because Downshift expects a DOM element.
        return (
          <div style={{ position: "relative" }}>
            {renderInput({
              error,
              fullWidth: true,
              label: label,
              InputLabelProps: getLabelProps(),
              InputProps: { onBlur, onFocus, placeholder: t("searchJobs") },
              inputProps,
              required: Boolean(required),
            })}
            {isOpen && inputValue && inputValue.length >= 2 ? (
              <Suggestions
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                inputValue={inputValue}
              />
            ) : null}
          </div>
        )
      }}
    </Downshift>
  )
}

export default JobTypeahead
