import React from "react"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import { useTranslation } from "react-i18next"

import InboxSearchField from "./InboxSearchField"
import HeaderConversationActions from "./HeaderConversationActions"
import EditSquareIcon from "~/components/icons/EditSquare"
import { InboxConversationStatus } from "~/types"

interface Props {
  readonly currentStatusFilter: InboxConversationStatus
  readonly onChangeSearchTerm: (term: string) => void
  readonly onClickChangeStatus: (newStatus: InboxConversationStatus) => void
  readonly onClickCompose: () => void
  readonly onClickLabel: () => void
  readonly onClickConnectWithJob: () => void
  readonly searchTerm: string
}

export default function HeaderBar({
  currentStatusFilter,
  onChangeSearchTerm,
  onClickChangeStatus,
  onClickCompose,
  onClickLabel,
  onClickConnectWithJob,
  searchTerm = "",
}: Props) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        backgroundColor: "white",
      }}
    >
      <InboxSearchField
        onChange={onChangeSearchTerm}
        sx={{
          borderRadius: "4px",
          width: "24rem",
          maxWidth: "400px",
        }}
        term={searchTerm}
      />
      <HeaderConversationActions
        currentStatusFilter={currentStatusFilter}
        onClickChangeStatus={onClickChangeStatus}
        onClickConnectWithJob={onClickConnectWithJob}
        onClickLabel={onClickLabel}
      />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Tooltip enterDelay={500} title={t("page.inbox.compose") as string}>
          <IconButton onClick={onClickCompose}>
            <EditSquareIcon color="#4b5563" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}
