import React from "react"
import { useTranslation } from "react-i18next"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import CancelIcon from "@mui/icons-material/Cancel"
import { SxProps, Theme } from "@mui/material/styles"
import AdvancedSearchIcon from "@mui/icons-material/TuneOutlined"

import { isBlank } from "~/util"

const TOOLTIP_DELAY = 500

interface Props {
  readonly onChange?: (term: string) => void
  readonly term: string
  readonly testID?: string
  readonly sx?: SxProps<Theme>
}

function InboxSearchField({ onChange, term, testID, sx = {} }: Props) {
  const { t } = useTranslation()
  const placeholder = t("search") as string

  return (
    <Paper
      data-testid={testID}
      elevation={0}
      sx={{
        padding: "0.125rem 0.25rem",
        display: "flex",
        alignItems: "center",
        borderRadius: "24px",
        border: "1px solid #ccc",
        ...sx,
      }}
    >
      <Box
        aria-label={placeholder}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          fontSize: "0.675rem",
          padding: "0",
          marginRight: "0.125rem",
        }}
      >
        <SearchIcon sx={{ color: (theme) => theme.fielderColors.mutedText }} />
      </Box>
      <InputBase
        inputProps={{ "aria-label": placeholder }}
        onChange={(e) => {
          onChange?.(e.target.value)
        }}
        placeholder={placeholder}
        sx={{
          flex: 1,
        }}
        value={term}
      />
      {!isBlank(term) ? (
        <IconButton
          aria-label={t("cancel") as string}
          onClick={() => {
            onChange?.("")
          }}
          sx={{
            padding: "0.4375rem",
            marginRight: "0.125rem",
          }}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
      <Tooltip enterDelay={TOOLTIP_DELAY} title={t("page.inbox.advancedSearch")}>
        <IconButton
          aria-label={t("advancedSearch") as string}
          onClick={() => {
            onChange?.("")
          }}
          sx={{}}
        >
          <AdvancedSearchIcon />
        </IconButton>
      </Tooltip>
    </Paper>
  )
}

export default InboxSearchField
