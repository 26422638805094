import React, { useState } from "react"
import * as Sentry from "@sentry/react"
import dayjs, { type Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

import DispatchCalendar from "~/components/DispatchCalendar/DispatchCalendar"
import EditJobAssignmentDialog from "~/components/EditJobAssignmentDialog"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import { ALL_JOB_ASSIGNMENTS } from "~/queries/allJobAssignments"
import { ALL_USERS } from "~/queries/allUsers"
import { CREATE_JOB_ASSIGNMENT } from "~/queries/createJobAssignment"
import { DELETE_JOB_ASSIGNMENT } from "~/queries/deleteJobAssignment"
import { EDIT_JOB_ASSIGNMENT } from "~/queries/editJobAssignment"
import { formatPersonName } from "~/util/stringUtils"
import { parseGraphQLErrorCode } from "~/util/parseGraphQLErrorCode"
import { DISPATCH } from "~/util/sections"
import { createDayJS } from "~/util/dateUtils"
import { useAuth } from "~/context/AuthContext"
import { TimeFrameOption, type Snack } from "~/types/appTypes"
import { type JobAssignment as IJobAssignment, UserStatus } from "~/types/apiTypes"
import useStore, {
  dispatchSelectedDateSelector,
  setDispatchSelectedDateSelector,
  dispatchSelectedTimeFrameSelector,
  setDispatchSelectedTimeFrameSelector,
  dispatchDailySortsSelector,
  setDispatchDailySortsSelector,
  findDispatchDailySortForDateSelector,
} from "~/store"

interface DispatchJobAssignment extends IJobAssignment {
  temporary?: boolean
  workOrderId?: string | null
}

function DispatchPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useAuth()
  const dispatchSelectedDate = useStore(dispatchSelectedDateSelector)
  const setSelectedDate = useStore(setDispatchSelectedDateSelector)
  const timeFrame = useStore(dispatchSelectedTimeFrameSelector)
  const setTimeFrame = useStore(setDispatchSelectedTimeFrameSelector)
  const dispatchDailySorts = useStore(dispatchDailySortsSelector)
  const setDispatchDailySorts = useStore(setDispatchDailySortsSelector)
  const findDispatchDailySortForDate = useStore(findDispatchDailySortForDateSelector)
  const [snack, setSnack] = useState<Snack>()
  const [assignments, setAssignments] = useState<DispatchJobAssignment[]>([])
  const [assignmentUnderEdit, setAssignmentUnderEdit] = useState<DispatchJobAssignment | null>()
  const [originalAssignment, setOriginalAssignment] = useState<DispatchJobAssignment | null>()
  const timeZone = user?.organization?.timeZone ?? "Etc/UTC"

  const selectedDate =
    dispatchSelectedDate && dayjs(dispatchSelectedDate).isValid()
      ? (createDayJS(dispatchSelectedDate, user?.organization?.timeZone) ?? dayjs())
      : dayjs()

  // Load all eligible Users
  const { loading: allUsersLoading } = useQuery(ALL_USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      first: 100000,
      roleNames: ["FIELD_TECH"],
      statuses: [UserStatus.ACTIVE, UserStatus.LOCKED, UserStatus.LOCKED_NEEDS_PASSWORD_CHANGE],
      includeArchived: false,
      sortBy: "lastName",
    },
    onCompleted: (data) => {
      const dispatchDailySort = findDispatchDailySortForDate(selectedDate)

      const allUserEdges = data.allUsers?.edges
      const allDispatchResources = allUserEdges
        ?.map((edge, index) => {
          const staffMember = edge.node
          const existingResource = dispatchDailySort?.resources?.find(
            (r) => r.id === staffMember.id
          )
          return {
            id: staffMember.id,
            name: formatPersonName(staffMember),
            user: staffMember,
            position: existingResource?.position ?? index,
          }
        })
        .sort((a, b) => a.position - b.position)

      // replace the entry in dispatchDailySorts for the selected date with the new resources
      const dispatchDailySortsCopy = [...dispatchDailySorts]
      dispatchDailySortsCopy.splice(
        dispatchDailySorts.findIndex((d) => d.date === selectedDate.format()),
        1,
        {
          date: selectedDate.format(),
          resources: allDispatchResources,
        }
      )

      setDispatchDailySorts(dispatchDailySortsCopy)
    },
  })

  // Load all job assignments for the currently selected date range
  const [
    getAllJobAssignments,
    { called: getAllJobAssignmentsWasCalled, refetch: refetchAssignments },
  ] = useLazyQuery(ALL_JOB_ASSIGNMENTS, {
    variables: {
      first: 100000, // 100,000 is kind of arbitrary but it ought to be way more than enough to deal with
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const allJobAssignments = data.allJobAssignments?.edges?.map((edge) => ({
        ...edge.node,
      }))
      setAssignments(allJobAssignments)
    },
  })

  if (!getAllJobAssignmentsWasCalled) {
    const unit = timeFrame === TimeFrameOption.DAY ? "day" : "week"
    getAllJobAssignments({
      variables: {
        startDate: selectedDate.startOf("day").toISOString(),
        endDate: selectedDate.add(1, unit).startOf("day").toISOString(),
      },
    })
  }

  const [createJobAssignment, { loading: createJobAssignmentLoading }] = useMutation(
    CREATE_JOB_ASSIGNMENT,
    {
      onCompleted: (data) => {
        setAssignmentUnderEdit(null)
        const savedAssignment = data.createJobAssignment.jobAssignment
        const tempAssignment = assignments.find(
          (ta) => ta.temporary === true && ta.job.id === savedAssignment.job.id
        )
        if (tempAssignment) {
          tempAssignment.id = savedAssignment.id
          tempAssignment.createdAt = savedAssignment.createdAt
          tempAssignment.createdBy = savedAssignment.createdBy
          tempAssignment.temporary = false
          setAssignments(assignments.concat([]))
        } else {
          console.error(
            "Hmmm, there is no temporary assignment for this newly created job assignment: ",
            savedAssignment
          )
        }
      },
      onError: (error) => {
        const errorCode = parseGraphQLErrorCode(error)
        if (
          errorCode.includes("job-assignment.assignee.overlap") ||
          errorCode.includes("job-assignment.assignee.duplicate")
        ) {
          setSnack({ messageKey: errorCode, variant: "error" })
        } else {
          setSnack({ messageKey: "error.server.general.message", variant: "error" })
          Sentry.captureException(error)
        }

        const removed = assignments.filter((ta) => ta.temporary !== true)
        setAssignments(removed)
        refetchAssignments()
      },
    }
  )

  // Edit an existing JobAssignment
  const [editJobAssignment, { loading: editJobAssignmentLoading }] = useMutation(
    EDIT_JOB_ASSIGNMENT,
    {
      onCompleted: () => {
        setAssignmentUnderEdit(null)
        setOriginalAssignment(null)
        if (timeFrame === TimeFrameOption.DAY) {
          // The user may have used the EditJobAssignmentDialog to change the date of the assignment
          setAssignments((prev) => prev.filter((a) => selectedDate.isSame(a.startDate, "day")))
        }
        refetchAssignments()
      },
      onError: (error) => {
        const errorCode = parseGraphQLErrorCode(error)
        if (
          errorCode.includes("job-assignment.assignee.overlap") ||
          errorCode.includes("job-assignment.assignee.duplicate")
        ) {
          setSnack({ messageKey: errorCode, variant: "error" })
        } else {
          setSnack({ messageKey: "error.server.general.message", variant: "error" })
          Sentry.captureException(error)
        }

        // Replace the original assignment. There should be an "updated" entry with the same ID.
        // Find and replace it with the original/unmodified version.
        if (originalAssignment?.id) {
          const idx = assignments.findIndex((a) => a.id === originalAssignment.id)
          assignments.splice(idx, 1, originalAssignment)
          setAssignments(assignments.concat([]))
          setOriginalAssignment(null)
        }

        refetchAssignments()
      },
    }
  )

  // Delete a JobAssignment
  const [deleteJobAssignment, { loading: deleteJobAssignmentLoading }] = useMutation(
    DELETE_JOB_ASSIGNMENT,
    {
      onCompleted: () => {
        setAssignmentUnderEdit(null)
        fetchAssignments(selectedDate, timeFrame)
      },
      onError: (error) => {
        Sentry.captureException(error)
        const errorCode = parseGraphQLErrorCode(error)
        setSnack({ messageKey: errorCode, variant: "error" })
        refetchAssignments()
      },
    }
  )

  const loading = allUsersLoading

  function fetchAssignments(withDate: Dayjs, withTimeFrame: TimeFrameOption) {
    const withTimezoneDate = createDayJS(withDate, timeZone) ?? dayjs()
    if (withTimeFrame === TimeFrameOption.DAY) {
      getAllJobAssignments({
        variables: {
          startDate: withTimezoneDate.startOf("day").subtract(1, "hour").toISOString(),
          endDate: withTimezoneDate.endOf("day").toISOString(),
        },
      })
    } else if (withTimeFrame === TimeFrameOption.WEEK) {
      getAllJobAssignments({
        variables: {
          startDate: withTimezoneDate.startOf("week").toISOString(),
          endDate: withTimezoneDate.endOf("week").toISOString(),
        },
      })
    } else if (withTimeFrame === "MAP") {
      getAllJobAssignments({
        variables: {
          startDate: withTimezoneDate.startOf("day").toISOString(),
          endDate: withTimezoneDate.endOf("day").toISOString(),
        },
      })
    }
  }

  const handleAddOrUpdateAssignment = (assignment: DispatchJobAssignment) => {
    if (assignment) {
      if (!assignment.assignees || assignment.assignees.length === 0) {
        setAssignmentUnderEdit(assignment)
      } else if (assignment.id) {
        const idx = assignments.findIndex((a) => a.id === assignment.id)
        if (idx < 0) {
          Sentry.captureMessage(
            `dispatch.handleAddorUpdateAssignment got an assignment to update (ie., it has an ID), but it was not found in the list of assignments. The given Assignment is: ${JSON.stringify(
              assignment
            )} `,
            "error"
          )
          setSnack({
            messageKey: "error.dispatch.assignment-unexpectedly-not-found",
            variant: "error",
          })
          return
        }

        // Grab a ref to the old assignment so we can replace it if there is an error.
        const oldAssignments = assignments.splice(idx, 1, assignment)
        setOriginalAssignment(oldAssignments[0])

        // Update the assignments eagerly here, rather than in the onComplete callback of editJobAssignment, because
        // doing it in editJobAssignment results in a janky UX where the Assignment UI element snaps back to its
        // original position until the onComplete handler is called.
        setAssignments(assignments.concat([]))

        editJobAssignment({
          variables: {
            id: assignment.id,
            assigneeUserIds: assignment.assignees?.map((a) => a.id),
            status: assignment.status,
            isLocked: assignment.isLocked,
            startDate: assignment.startDate,
            endDate: assignment.endDate,
            workOrderId: assignment.workOrderId,
          },
        })
      } else {
        // optimistically add the new assignment
        setAssignments([
          ...assignments,
          {
            ...assignment,
            id: `temporary`,
            temporary: true,
          },
        ])
        setTimeout(() => {
          createJobAssignment({
            variables: {
              jobId: assignment.job?.id,
              assigneeUserIds: assignment.assignees?.map((a) => a.id),
              status: assignment.status,
              isLocked: assignment.isLocked,
              startDate: assignment.startDate,
              endDate: assignment.endDate,
            },
          })
        }, 100)
      }
    }
  }

  function handleDeleteAssignment(assignment: DispatchJobAssignment) {
    if (assignment) {
      deleteJobAssignment({
        variables: {
          id: assignment.id,
        },
      })
    }
  }

  if (!user) {
    navigate("/app/unauthorized")
    return null
  }

  return (
    <>
      <Seo title={t("sectionTitle.dispatch")} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={DISPATCH}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader icon={DISPATCH.icon} leafTitleKey={DISPATCH.titleKey} />
          {loading ? (
            <CircularProgress />
          ) : (
            <DispatchCalendar
              assignments={assignments}
              onAddOrUpdateAssignment={handleAddOrUpdateAssignment}
              onChangeDate={(d) => {
                setSelectedDate(d.format())
                fetchAssignments(d, timeFrame)
              }}
              onChangeTimeFrame={(tf) => {
                setTimeFrame(tf)
                if (tf === "MAP") {
                  fetchAssignments(selectedDate, TimeFrameOption.DAY)
                } else {
                  fetchAssignments(selectedDate, tf)
                }
              }}
              onDoubleClickAssignment={(assignment: DispatchJobAssignment) =>
                setAssignmentUnderEdit(assignment)
              }
              onReorderResources={(updatedResources) => {
                const dispatchDailySortsCopy = [...dispatchDailySorts]
                const existingEntryIndex = dispatchDailySortsCopy.findIndex(
                  (d) => d.date === selectedDate.format()
                )
                const updatedEntry = {
                  date: selectedDate.format(),
                  resources: updatedResources,
                }
                if (existingEntryIndex >= 0) {
                  dispatchDailySortsCopy.splice(existingEntryIndex, 1, updatedEntry)
                } else {
                  dispatchDailySortsCopy.push(updatedEntry)
                }
                dispatchDailySortsCopy.sort((a, b) => {
                  return dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1
                })
                setDispatchDailySorts(dispatchDailySortsCopy)
              }}
              resources={findDispatchDailySortForDate(selectedDate)?.resources ?? []}
              selectedDate={selectedDate}
              timeFrame={timeFrame}
              timeZone={timeZone}
            />
          )}
        </Box>
      </MainLayout>
      {assignmentUnderEdit ? (
        <EditJobAssignmentDialog
          assignment={assignmentUnderEdit}
          onCancel={() => setAssignmentUnderEdit(null)}
          onDelete={() => handleDeleteAssignment(assignmentUnderEdit)}
          onSave={(updated) => handleAddOrUpdateAssignment(updated)}
          open
          waitingOnCreate={createJobAssignmentLoading}
          waitingOnDelete={deleteJobAssignmentLoading}
          waitingOnEdit={editJobAssignmentLoading}
        />
      ) : null}
    </>
  )
}

export default DispatchPage
