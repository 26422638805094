import gql from "graphql-tag"

export const SEND_JOB_EMAIL = gql`
  mutation SendJobEmail(
    $jobId: ID!
    $toRecipients: [String!]!
    $ccRecipients: [String]
    $bccRecipients: [String]
    $subject: String
    $body: String
    $attachmentIds: [ID!]
    $estimateIds: [ID!]
    $workOrderIds: [ID!]
    $invoiceIds: [ID!]
    $customAttachments: [CustomAttachment!]
    $inboxConversationId: ID
  ) {
    sendJobEmail(
      input: {
        jobId: $jobId
        toRecipients: $toRecipients
        ccRecipients: $ccRecipients
        bccRecipients: $bccRecipients
        subject: $subject
        body: $body
        attachmentIds: $attachmentIds
        estimateIds: $estimateIds
        workOrderIds: $workOrderIds
        invoiceIds: $invoiceIds
        customAttachments: $customAttachments
        inboxConversationId: $inboxConversationId
      }
    ) {
      inboxConversation {
        id
        status
      }
    }
  }
`
