import gql from "graphql-tag"

export default gql`
  fragment AttachmentFragment on Attachment {
    id
    contentType
    name
    signedUrl
    isArchived
    sizeInBytes
    previews(sizes: [SMALL]) {
      id
      previewSize
      signedUrl
    }
  }
`
