import React from "react"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Switch from "@mui/material/Switch"
import Checkbox from "@mui/material/Checkbox"

import CategorySectionHeader from "./CategorySectionHeader"
import {
  EditNotificationSettingInput,
  NotificationCategory,
  NotificationSetting,
  NotificationType,
} from "~/types"

function ChannelCheckboxLabel({ label }: { readonly label: string }) {
  return (
    <Box
      sx={(theme) => {
        return {
          marginTop: "1rem",
          fontSize: "0.875rem",
          fontWeight: "600",
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        }
      }}
    >
      {label}
    </Box>
  )
}

interface ResponsiveSwitchProps {
  readonly setting?: NotificationSetting
  readonly notificationTypeId: string
  readonly onChangeSetting: (input: EditNotificationSettingInput) => void
}
function ResponsiveSwitch({ setting, notificationTypeId, onChangeSetting }: ResponsiveSwitchProps) {
  return (
    <Switch
      checked={
        Boolean(setting?.emailEnabled) ||
        Boolean(setting?.smsEnabled) ||
        Boolean(setting?.inboxEnabled)
      }
      color="secondary"
      onChange={(event) => {
        // if the user explicitly clicked the Switch to the ON position,
        // then turn on the inbox notifications (but not email or SMS) by default.
        // this is more of a convenience to turn OFF all channels rather than to turn ON all channels
        onChangeSetting({
          notificationTypeId: notificationTypeId,
          emailEnabled: false,
          smsEnabled: false,
          inboxEnabled: event.target.checked,
        })
      }}
    />
  )
}

interface Props {
  readonly category: NotificationCategory
  readonly notificationTypes: NotificationType[]
  readonly notificationSettings?: NotificationSetting[]
  readonly onChangeSetting: (input: EditNotificationSettingInput) => void
}

function CategorySection({
  category,
  notificationTypes,
  notificationSettings,
  onChangeSetting,
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      <CategorySectionHeader
        description={t(`component.notificationSettings.category.${category}.description`)}
        title={t(`component.notificationSettings.category.${category}.title`)}
      />
      {notificationTypes.map((n, idx) => {
        const setting = notificationSettings?.find((s) => s.notificationType.id === n.id)
        return (
          <React.Fragment key={n.id}>
            <Box
              sx={(theme) => {
                return {
                  display: "none",
                  gridColumn: "1 / span 1",
                  [theme.breakpoints.up("sm")]: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  },
                }
              }}
            >
              <ResponsiveSwitch
                notificationTypeId={n.id}
                onChangeSetting={onChangeSetting}
                setting={setting}
              />
            </Box>
            <Box
              sx={(theme) => {
                return {
                  display: "flex",
                  flexDirection: "column",
                  gridColumn: "1 / span 11",
                  [theme.breakpoints.up("sm")]: {
                    gridColumn: "2 / span 7",
                  },
                }
              }}
            >
              <Box sx={{ fontSize: "0.875rem", fontWeight: "700" }}>
                {t(`component.notificationSettings.notificationType.${n.name}.title`)}
              </Box>
              <Box
                sx={{
                  fontSize: "0.85rem",
                  color: (theme) => theme.fielderColors.mutedText,
                }}
              >
                {t(`component.notificationSettings.notificationType.${n.name}.description`)}
              </Box>
            </Box>
            <Box
              sx={(theme) => {
                return {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  gridColumn: "12 / span 1",
                  [theme.breakpoints.up("sm")]: {
                    display: "none",
                  },
                }
              }}
            >
              <ResponsiveSwitch
                notificationTypeId={n.id}
                onChangeSetting={onChangeSetting}
                setting={setting}
              />
            </Box>
            {n.allowEmail ? (
              <Box
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    justifySelf: "center",
                    gridColumn: "1 / span 4",
                    [theme.breakpoints.up("sm")]: {
                      gridColumn: "10 / span 1",
                    },
                  }
                }}
              >
                <ChannelCheckboxLabel label={t("component.notificationSettings.channel.email")} />
                <Checkbox
                  checked={Boolean(setting?.emailEnabled)}
                  inputProps={{
                    "aria-label": t("component.notificationSettings.channel.checkboxLabel", {
                      channel: t("component.notificationSettings.channel.email"),
                    }),
                  }}
                  onChange={(event) => {
                    onChangeSetting({
                      notificationTypeId: n.id,
                      emailEnabled: event.target.checked,
                      smsEnabled: Boolean(setting?.smsEnabled),
                      inboxEnabled: Boolean(setting?.inboxEnabled),
                    })
                  }}
                />
              </Box>
            ) : null}
            {n.allowSms ? (
              <Box
                sx={(theme) => {
                  return {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    justifySelf: "center",
                    gridColumn: "5 / span 4",
                    [theme.breakpoints.up("sm")]: {
                      gridColumn: "11 / span 1",
                    },
                  }
                }}
              >
                <ChannelCheckboxLabel label={t("component.notificationSettings.channel.sms")} />
                <Checkbox
                  checked={Boolean(setting?.smsEnabled)}
                  inputProps={{
                    "aria-label": t("component.notificationSettings.channel.checkboxLabel", {
                      channel: t("component.notificationSettings.channel.sms"),
                    }),
                  }}
                  onChange={(event) => {
                    onChangeSetting({
                      notificationTypeId: n.id,
                      emailEnabled: Boolean(setting?.emailEnabled),
                      smsEnabled: event.target.checked,
                      inboxEnabled: Boolean(setting?.inboxEnabled),
                    })
                  }}
                />
              </Box>
            ) : null}
            <Box
              sx={(theme) => {
                return {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  justifySelf: "center",
                  gridColumn: "9 / span 4",
                  textAlign: "center",
                  [theme.breakpoints.up("sm")]: {
                    gridColumn: "12 / span 1",
                  },
                }
              }}
            >
              <ChannelCheckboxLabel
                label={t("component.notificationSettings.channel.notificationCenter")}
              />
              <Checkbox
                checked={Boolean(setting?.inboxEnabled)}
                inputProps={{
                  "aria-label": t("component.notificationSettings.channel.checkboxLabel", {
                    channel: t("component.notificationSettings.channel.notificationCenter"),
                  }),
                }}
                onChange={(event) => {
                  onChangeSetting({
                    notificationTypeId: n.id,
                    emailEnabled: Boolean(setting?.emailEnabled),
                    smsEnabled: Boolean(setting?.smsEnabled),
                    inboxEnabled: event.target.checked,
                  })
                }}
              />
            </Box>
            <Divider sx={{ gridColumn: "1 / -1 ", marginTop: "1rem", marginBottom: "1rem" }} />
            {idx === notificationTypes.length - 1 ? <Box sx={{ marginBottom: "2rem" }} /> : null}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default CategorySection
