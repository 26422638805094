import Box from "@mui/material/Box"
import React from "react"

interface Props {
  readonly label: string
}

function ChannelHeader({ label }: Props) {
  return (
    <Box
      sx={(theme) => {
        return {
          display: "none",
          [theme.breakpoints.up("sm")]: {
            display: "block",
            gridRowStart: "auto",
            gridRowEnd: "span 2",
            justifySelf: "center",
            alignSelf: "end",
            fontSize: "0.875rem",
            fontWeight: "600",
            textAlign: "center",
          },
        }
      }}
    >
      {label}
    </Box>
  )
}

export default ChannelHeader
