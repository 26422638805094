/* eslint-disable react/jsx-no-literals */
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import dayjs, { type Dayjs } from "dayjs"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"
import CancelIcon from "@mui/icons-material/Cancel"
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import FormHelperText from "@mui/material/FormHelperText"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import CircularProgress from "@mui/material/CircularProgress"
import MenuItem from "@mui/material/MenuItem"

import FielderIconButton from "./FielderIconButton"
import FielderTextField from "./FielderTextField"
import SelectorField from "./SelectorField"
import WorkOrderSelect from "./WorkOrderSelect"
import { formatAddress, truncate } from "~/util"
import { DefaultPermission } from "~/types/permissions"
import type { JobAssignment, User, WorkOrder } from "~/types/apiTypes"
import { JobAssignmentStatus } from "~/types/apiTypes"
import SaveButton from "./SaveButton"
import { MAX_DATE, MIN_DATE } from "./DispatchCalendar/Constants"
import { useAuth } from "~/context/AuthContext"
import MultiUserSelect from "./MultiUserSelect"
import { getAssignmentStatusColor } from "~/util/jobAssignmentColors"

interface TimeOption {
  readonly id: string
  readonly hour: number
  readonly minute: number
  readonly display12h: string
  readonly display24h: string
  readonly name: string
}

interface JobAssignmentStatusOption {
  readonly id: JobAssignmentStatus
  readonly name: string
}

function getTimeOptions(): TimeOption[] {
  const options = [] as TimeOption[]
  for (let h = 0; h < 24; h += 1) {
    const hour = h % 12 || 12
    const ampm = h < 12 ? "AM" : "PM"
    for (let m = 0; m < 4; m += 1) {
      const minute = m * 15
      const time12h = `${hour}:${minute === 0 ? "00" : minute} ${ampm}`
      const time24h = `${h}:${minute === 0 ? "00" : minute}`
      options.push({
        id: time24h,
        hour: h,
        minute,
        display12h: time12h,
        display24h: time24h,
        name: time12h,
      })
    }
  }

  return options
}

interface Props {
  readonly assignment: JobAssignment
  readonly errorKey?: string | null
  readonly onCancel: () => void
  readonly onDelete: (assignment: JobAssignment) => void
  readonly onSave: (assignment: JobAssignment) => void
  readonly open?: boolean
  readonly waitingOnCreate?: boolean
  readonly waitingOnDelete?: boolean
  readonly waitingOnEdit?: boolean
}

function EditJobAssignmentDialog({
  assignment,
  errorKey,
  onCancel,
  onDelete,
  onSave,
  open = false,
  waitingOnCreate = false,
  waitingOnDelete = false,
  waitingOnEdit = false,
}: Props) {
  const { t } = useTranslation()
  const { hasPermissions } = useAuth()
  const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false)
  const TIME_OPTIONS: TimeOption[] = useMemo(() => getTimeOptions(), [])
  const statusOptions: JobAssignmentStatusOption[] = useMemo(() => {
    return [
      { id: "TENTATIVE", name: t("jobAssignmentStatus.TENTATIVE") },
      { id: "CONFIRMED", name: t("jobAssignmentStatus.CONFIRMED") },
      { id: "COMPLETED", name: t("jobAssignmentStatus.COMPLETED") },
    ] as JobAssignmentStatusOption[]
  }, [t])
  const [startDate, setStartDate] = useState<Dayjs | null>(() => dayjs(assignment.startDate))
  const [endDate, setEndDate] = useState<Dayjs | null>(() => dayjs(assignment.endDate))
  const [startTime, setStartTime] = useState<TimeOption>(() => {
    if (startDate) {
      const hour = startDate.get("hour")
      const minute = startDate.get("minute") ?? 0
      const adjustedMinute = (Math.round(minute / 15) % 4) * 15
      return (
        TIME_OPTIONS.find((t) => t.hour === hour && t.minute === adjustedMinute) ?? TIME_OPTIONS[12]
      )
    } else {
      return TIME_OPTIONS.find((t) => t.id === "11:00") ?? TIME_OPTIONS[12]
    }
  })
  const [endTime, setEndTime] = useState<TimeOption>(() => {
    if (endDate) {
      const hour = endDate.get("hour")
      const minute = endDate.get("minute") ?? 0
      const adjustedMinute = (Math.round(minute / 15) % 4) * 15
      return (
        TIME_OPTIONS.find((t) => t.hour === hour && t.minute === adjustedMinute) ?? TIME_OPTIONS[16]
      )
    } else {
      return (
        TIME_OPTIONS.find((t) => t.hour === startTime.hour + 1 && t.minute === startTime.minute) ??
        TIME_OPTIONS[16]
      )
    }
  })
  const [assignees, setAssignees] = useState<User[]>(assignment.assignees ?? [])
  const [workOrder, setWorkOrder] = useState<WorkOrder | null>(assignment.workOrder ?? null)
  const [status, setStatus] = useState<JobAssignmentStatus>(assignment.status)
  const [isLocked, setIsLocked] = useState<boolean>(assignment.isLocked)
  const [errors, setErrors] = useState(() => ({
    startDate: null,
    endDate: null,
    assignees: null,
    server: null,
  }))
  const canEdit = hasPermissions?.([DefaultPermission.UpdateJobAssignment])

  useEffect(() => {
    if (errorKey?.includes("assignee.overlap")) {
      setErrors((prev) => ({
        ...prev,
        server: t(`component.editJobAssignmentDialog.errors.assigneeOverlap`),
      }))
    } else if (errorKey?.includes("job-assignment.already-associated")) {
      setErrors((prev) => ({
        ...prev,
        server: t(`component.editJobAssignmentDialog.errors.${errorKey}`),
      }))
    } else if (errorKey) {
      setErrors((prev) => ({
        ...prev,
        server: t(errorKey),
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        server: null,
      }))
    }
  }, [errorKey, t])

  useEffect(() => {
    if (startDate) {
      setErrors((prev) => ({
        ...prev,
        startDate: null,
      }))
    }
  }, [startDate])

  useEffect(() => {
    if (endDate) {
      setErrors((prev) => ({
        ...prev,
        endDate: null,
      }))
    }
  }, [endDate])

  const adjustStartDateIfNeeded = (endDateValue = endDate) => {
    if (endDateValue && dayjs(endDateValue).isValid()) {
      if (!startDate) {
        setStartDate(endDateValue.subtract(1, "hours"))
      } else if (
        !startDate.isSame(endDateValue, "day") ||
        startDate.isSame(endDateValue, "minute")
      ) {
        setStartDate(endDateValue.set("hour", endDateValue.get("hour") - 1))
      } else if (endDateValue.isBefore(startDate)) {
        setStartDate(endDateValue.subtract(1, "hours"))
      }
    }
  }

  const adjustEndDateIfNeeded = (startDateValue = startDate) => {
    if (startDateValue && dayjs(startDateValue).isValid()) {
      let newEndDate = endDate?.set("day", startDateValue.get("day"))
      if (!newEndDate || newEndDate.isBefore(startDateValue)) {
        newEndDate = startDateValue.add(1, "hour")
      }

      setEndDate(newEndDate)
      const endHour = newEndDate.get("hour")
      const endMinute = newEndDate.get("minute")
      const newEndTime = TIME_OPTIONS.find((t) => t.hour === endHour && t.minute === endMinute)
      if (newEndTime) {
        setEndTime(newEndTime)
      }
    }
  }

  function hasErrors() {
    const { server: _, ...clientSideErrors } = errors
    return Object.values(clientSideErrors).some(Boolean)
  }

  function handleSave() {
    if (!startDate || !endDate || !assignees || assignees.length === 0) {
      if (!assignees || assignees.length === 0) {
        setErrors({
          ...errors,
          assignees: t("component.editJobAssignmentDialog.errors.required"),
        })
      }

      if (!startDate) {
        setErrors({
          ...errors,
          startDate: t("component.editJobAssignmentDialog.errors.required"),
        })
      }

      if (!endDate) {
        setErrors({
          ...errors,
          endDate: t("component.editJobAssignmentDialog.errors.required"),
        })
      }

      return
    }

    setErrors({
      ...errors,
      assignees: null,
    })

    const updated = {
      ...assignment,
      assignees,
      isLocked,
      status,
      startDate: startDate.utc().format(),
      endDate: endDate.utc().format(),
      workOrderId: workOrder ? workOrder.id : null,
    }

    onSave(updated)
  }

  return (
    <>
      <Dialog
        aria-labelledby="form-dialog-title"
        data-testid="EditJobAssignmentDialog"
        fullWidth
        maxWidth="sm"
        onClose={onCancel}
        open={open}
      >
        <DialogTitle
          sx={{
            py: "0.625rem",
            px: "1.5rem",
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          {assignment.id ? t("editJobAssignment") : t("createJobAssignment")}
          <FielderIconButton
            aria-label={t("close") as string}
            onClick={onCancel}
            sx={{
              position: "absolute",
              right: "0.5rem",
              top: "0.5rem",
            }}
            title={t("close") as string}
          >
            <CancelIcon />
          </FielderIconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              backgroundColor: dialogBackgroundColor,
              paddingBottom: 0,
              paddingTop: "1.5rem",
              px: "1rem",
            }}
          >
            <form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  marginBottom: "1.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      maxWidth: "256px",
                    }}
                  >
                    <Box>
                      <Link
                        id="viewJobDetails"
                        style={{
                          fontWeight: "800",
                          marginRight: "0.625rem",
                          textDecoration: "underline",
                          color: "inherit",
                        }}
                        to={`/app/jobs/edit/${assignment.job.id}`}
                      >
                        {t("job")} #{assignment.job.number}
                      </Link>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ fontWeight: "600", color: (theme) => theme.fielderColors.text }}>
                        {assignment.job.customer?.name}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "0.875rem",
                          color: (theme) => theme.fielderColors.mutedText,
                        }}
                      >
                        {formatAddress(assignment.job.address.addressString)}
                      </Box>
                    </Box>
                  </Box>
                  {assignment.id && canEdit ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        maxWidth: "200px",
                        fontSize: "0.625rem",
                        textAlign: "center",
                      }}
                    >
                      {isLocked ? (
                        <>
                          <FielderIconButton
                            aria-label={t("unlock") as string}
                            onClick={() => setIsLocked(false)}
                            sx={classes.lockIcon}
                            title={t("unlock") as string}
                          >
                            <LockOutlinedIcon />
                          </FielderIconButton>
                          <Box>{t("component.editJobAssignmentDialog.unlockInstructions")}</Box>
                        </>
                      ) : (
                        <>
                          <FielderIconButton
                            aria-label={t("lock") as string}
                            onClick={() => setIsLocked(true)}
                            sx={classes.lockIcon}
                            title={t("lock") as string}
                          >
                            <LockOpenOutlinedIcon />
                          </FielderIconButton>
                          <Box>{t("component.editJobAssignmentDialog.lockInstructions")}</Box>
                        </>
                      )}
                    </Box>
                  ) : null}
                </Box>
                {assignment?.job?.description && assignment.job.description.length > 0 ? (
                  <Box
                    sx={{
                      fontSize: "0.825rem",
                      fontStyle: "italic",
                      color: (theme) => theme.fielderColors.mutedText,
                    }}
                  >
                    {truncate(assignment.job.description, 280, true)}
                  </Box>
                ) : null}
              </Box>
              {errors.server ? (
                <Box
                  sx={(theme) => ({
                    marginBottom: "1.25rem",
                    padding: "1.25rem",
                    borderRadius: "4px",
                    border: `1px solid ${theme.fielderColors.error}`,
                    backgroundColor: "#f2dede",
                    color: theme.fielderColors.error,
                    fontWeight: 500,
                    fontSize: "0.9rem",
                  })}
                >
                  {t(errors.server)}
                </Box>
              ) : null}
              <Box sx={classes.formRow}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                  <DatePicker
                    disabled={isLocked || !canEdit}
                    format="L"
                    label={t("startDate") as string}
                    maxDate={MAX_DATE}
                    onAccept={adjustEndDateIfNeeded}
                    onChange={(val) => {
                      setStartDate(val)
                      if (val && dayjs(val).isValid()) {
                        setErrors({
                          ...errors,
                          startDate: null,
                          server: null,
                        })
                      }
                    }}
                    slotProps={{
                      field: {
                        clearable: true,
                      },
                      textField: {
                        InputProps: {
                          disableUnderline: true,
                        },
                        error: !!errors.startDate,
                        onBlur: () => {
                          if (!startDate) {
                            setErrors({
                              ...errors,
                              startDate: t("component.editJobAssignmentDialog.errors.required"),
                            })
                          } else {
                            adjustEndDateIfNeeded(startDate)
                          }
                        },
                        fullWidth: true,
                        required: true,
                        helperText: errors.startDate ? errors.startDate : null,
                      },
                    }}
                    slots={{
                      textField: FielderTextField,
                    }}
                    sx={{ flex: 1 }}
                    value={startDate}
                  />
                  <SelectorField
                    label={t("startTime") as string}
                    name="type"
                    onChange={(value) => {
                      setStartTime(value)
                      if (value) {
                        const { hour, minute } = value
                        const startDayJs = startDate ?? dayjs()
                        const adjustedStart = startDayJs.set("hour", hour).set("minute", minute)
                        setStartDate(adjustedStart)
                        adjustEndDateIfNeeded(adjustedStart)
                      }
                    }}
                    options={TIME_OPTIONS}
                    renderOption={(option) => {
                      const timeOption = option as TimeOption
                      return (
                        <MenuItem key={timeOption.id} value={timeOption.id}>
                          <Box>{timeOption.display12h}</Box>
                        </MenuItem>
                      )
                    }}
                    sx={{ maxWidth: "150px" }}
                    value={startTime.id}
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box sx={classes.formRow}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                  <DatePicker
                    disabled={isLocked || !canEdit}
                    format="L"
                    label={t("endDateTime")}
                    maxDate={MAX_DATE}
                    minDate={startDate ?? MIN_DATE}
                    onAccept={adjustStartDateIfNeeded}
                    onChange={(val) => {
                      setEndDate(val)
                      if (val && dayjs(val).isValid()) {
                        setErrors({
                          ...errors,
                          endDate: null,
                          server: null,
                        })
                      }
                      if (!endDate) {
                        setErrors({
                          ...errors,
                          endDate: t("component.editJobAssignmentDialog.errors.required"),
                        })
                      }
                    }}
                    openTo="day"
                    slotProps={{
                      field: {
                        clearable: true,
                      },
                      textField: {
                        InputProps: {
                          disableUnderline: true,
                        },
                        error: !!errors.endDate,
                        onBlur: () => {
                          if (!endDate) {
                            setErrors({
                              ...errors,
                              endDate: t("component.editJobAssignmentDialog.errors.required"),
                            })
                          } else {
                            adjustStartDateIfNeeded(endDate)
                          }
                        },
                        fullWidth: true,
                        required: true,
                        helperText: errors.endDate ? errors.endDate : null,
                      },
                    }}
                    slots={{
                      textField: FielderTextField,
                    }}
                    value={endDate}
                  />
                  <SelectorField
                    label={t("endTime") as string}
                    name="type"
                    onChange={(value) => {
                      setEndTime(value)
                      if (value) {
                        const { hour, minute } = value
                        const endDayJs = endDate ?? dayjs()
                        const adjustedEnd = endDayJs.set("hour", hour).set("minute", minute)
                        setEndDate(adjustedEnd)
                        adjustStartDateIfNeeded(adjustedEnd)
                      }
                    }}
                    options={TIME_OPTIONS.filter(
                      (o) =>
                        startDate?.get("day") !== endDate?.get("day") ||
                        o.hour > startTime.hour ||
                        (o.hour === startTime.hour && o.minute > startTime.minute)
                    )}
                    renderOption={(option) => {
                      const timeOption = option as TimeOption
                      return (
                        <MenuItem key={timeOption.id} value={timeOption.id}>
                          <Box>{timeOption.display12h}</Box>
                        </MenuItem>
                      )
                    }}
                    sx={{ maxWidth: "150px" }}
                    value={endTime.id}
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box sx={classes.formRow}>
                <MultiUserSelect
                  aria-label={t("assignees") as string}
                  error={Boolean(errors.assignees)}
                  id="installers"
                  label={t("component.editJobAssignmentDialog.assignee") as string}
                  name="assignees"
                  onChange={(users: User[] | null) => {
                    setAssignees(users ?? [])
                    if (!users || users.length === 0) {
                      setErrors({
                        ...errors,
                        assignees: t("component.editJobAssignmentDialog.errors.required"),
                        server: null,
                      })
                    } else {
                      setErrors({
                        ...errors,
                        assignees: null,
                        server: null,
                      })
                    }
                  }}
                  required
                  roleNames={["FIELD_TECH"]}
                  selectedUsers={assignees}
                />
                {errors.assignees ? (
                  <FormHelperText
                    classes={{ root: classes.helperText }}
                    data-testid="assigneesError"
                    error
                  >
                    {errors.assignees}
                  </FormHelperText>
                ) : null}
              </Box>
              <Box sx={classes.formRow}>
                <SelectorField
                  disabled={isLocked || !canEdit}
                  label={t("status") as string}
                  name="status"
                  onChange={(val) => {
                    setStatus(val.id)
                  }}
                  options={statusOptions}
                  renderOption={(option: JobAssignmentStatusOption) => {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "1rem",
                              height: "1rem",
                              borderRadius: "15%",
                              backgroundColor: getAssignmentStatusColor(option.id),
                            }}
                          />
                          <Box>{option.name}</Box>
                        </Box>
                      </MenuItem>
                    )
                  }}
                  renderValue={(value) => {
                    const status = statusOptions.find(
                      (o) => o.id === value
                    ) as JobAssignmentStatusOption
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "1rem",
                            height: "1rem",
                            borderRadius: "15%",
                            backgroundColor: getAssignmentStatusColor(status.id),
                          }}
                        />
                        <Box>{status.name}</Box>
                      </Box>
                    )
                  }}
                  required
                  value={status}
                />
              </Box>
              <Box sx={classes.formRow}>
                <WorkOrderSelect
                  disabled={isLocked || !canEdit}
                  filterAssigned
                  jobId={assignment.job.id}
                  label={t("workOrder")}
                  name="workOrder"
                  onChange={(workOrder) => setWorkOrder(workOrder)}
                  selectedWorkOrder={workOrder}
                />
              </Box>
            </form>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: "1.5rem",
            paddingBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="secondary"
            data-testid="cancelButton"
            disabled={waitingOnCreate || waitingOnDelete || waitingOnEdit}
            onClick={onCancel}
            variant="outlined"
          >
            <Box>{t("cancel")}</Box>
          </Button>
          {assignment.id && canEdit ? (
            <Button
              data-testid="deleteButton"
              disabled={isLocked || waitingOnCreate || waitingOnDelete || waitingOnEdit}
              onClick={() => {
                setOpenDeleteAlertDialog(true)
              }}
              sx={(theme) => ({
                marginRight: "1.25rem",
                border: `1px solid ${theme.fielderColors.error}`,
                color: theme.fielderColors.error,
                "&:hover": {
                  border: `1px solid ${theme.fielderColors.error}`,
                  background: `${theme.fielderColors.error}0F`,
                },
              })}
              variant="outlined"
            >
              {waitingOnDelete ? (
                <CircularProgress color="secondary" size={20} thickness={6.0} />
              ) : (
                <Box>{t("delete")}</Box>
              )}
            </Button>
          ) : null}
          {canEdit ? (
            <SaveButton
              disabled={waitingOnCreate || waitingOnDelete || waitingOnEdit || hasErrors()}
              loading={waitingOnCreate || waitingOnEdit}
              onClick={handleSave}
            />
          ) : null}
        </DialogActions>
      </Dialog>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={() => setOpenDeleteAlertDialog(false)}
        open={openDeleteAlertDialog}
      >
        <DialogTitle id="alert-dialog-title">{t("areYouSure")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("component.editJobAssignmentDialog.deleteConfirmationPrompt")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenDeleteAlertDialog(false)}>
            {t("no")}
          </Button>
          <Button autoFocus color="primary" onClick={() => onDelete(assignment)}>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const dialogBackgroundColor = "#FFFFFF"
const classes = {
  lockIcon: {
    color: "#747474",
    "& svg": {
      fontSize: "2.1875rem",
    },
  },
  formRow: {
    marginBottom: "1.25rem",
  },
  helperText: {
    fontWeight: 500,
    fontSize: "1rem",
  },
} as const

export default EditJobAssignmentDialog
