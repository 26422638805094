import gql from "graphql-tag"
import { useQuery } from "@apollo/client"

import { DefaultPermission } from "~/types"
import { useAuth } from "~/context/AuthContext"

export const GET_UNREAD_INBOX_CONVERSATION_COUNT = gql`
  query GetUnreadInboxConversationCount($id: ID!) {
    getOrganizationById(id: $id) {
      id
      unreadInboxConversationCount
    }
  }
`

export default function useGetUnreadInboxConversationCount(): number {
  const { user, hasPermissions } = useAuth()

  const { data } = useQuery(GET_UNREAD_INBOX_CONVERSATION_COUNT, {
    variables: {
      id: user?.organization?.id,
    },
    skip: !user?.organization?.id || !hasPermissions?.([DefaultPermission.ReadInbox]), // Do not make the network request if the user does not have the required permissions! Otherwise they may not be able to do anything in the app.
    pollInterval: 60000,
    fetchPolicy: "network-only",
  })
  const organization = data?.getOrganizationById
  return organization?.unreadInboxConversationCount ?? 0
}
